<template>
    <div>
      <div class="mx-auto px-6 sm:px-6 py-4">
        <div class="py-4">
          <div class="grid grid-cols-1 sm:grid-cols-2 py-4">
            <div>
              <h2 class="text-2xl font-semibold leading-tight">{{titlePage}}</h2>
            </div>
            <div class="text-left py-4 sm:py-0 sm:text-right">
              <button class="save" @click="validateBeforeSubmit">
                <i class="fa fa-save"></i>
                Save
              </button>
              <button class="save" @click="$router.push({name: 'Group',params: {pageno:pageno,perpage: perPage,order:currentSortRoute,search:search} })">
                <i class="fa fa-undo"></i>
                Cancel
              </button>
            </div>
          </div>
          <div class="shadow-md">
            <form @submit.prevent="validateBeforeSubmit" class="form" id="frm">
              <div class="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-gray-400 rounded-t"></div>
              <div class="bg-white space-y-6">
                <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="name">
                        Name *
                      </label>
                      <input name="name" v-model="name" v-validate="'required'" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('name') }" id="name" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('name')">{{errors.first('name')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="new_user_title">
                        Title for New Users
                      </label>
                      <input name="new_user_title" v-model="new_user_title" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('new_user_title') }" id="new_user_title" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('new_user_title')">{{errors.first('new_user_title')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="theme">
                        Theme
                      </label>
                      <div class="relative">
                        <select name="theme" id="theme" v-model="theme" :class="{'block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded':true,'border-red-600': errors.has('theme') }">
                          <option value=""></option>
                          <option value="default">default</option>
                          <option value="nyx">nyx</option>
                          <option value="root">root</option>
                        </select>
                        <div class="pointer-events-none absolute right-1 top-4 flex items-center px-2 text-grey-darker">
                          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                      </div>
                      <p class="text-red-600 text-xs italic" v-show="errors.has('theme')">{{errors.first('theme')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="icon">
                        Icon
                      </label>
                      <input name="icon" v-model="icon" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('icon') }" id="icon" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('icon')">{{errors.first('icon')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="landing_page">
                        Langing Page
                      </label>
                      <input name="landing_page" v-model="landing_page" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('landing_page') }" id="landing_page" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('landing_page')">{{errors.first('landing_page')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3 pt-6 mb-6 md:mb-0">
                      <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input type="radio" name="is_default" v-model="is_default" value="0" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
                        <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                      </div>
                      <label for="toggle" class="text-xs text-gray-700 pr-3">Neither</label>
                      <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input type="radio" name="is_default" v-model="is_default" value="1" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
                        <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                      </div>
                      <label for="toggle" class="text-xs text-gray-700 pr-3">Default</label>
                      <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input type="radio" name="is_default" v-model="is_default" value="2" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
                        <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                      </div>
                      <label for="toggle" class="text-xs text-gray-700">Default Primary</label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 py-4">
            <div>
      
            </div>
            <div class="text-left py-4 sm:py-0 sm:text-right">
              <button class="save" @click="validateBeforeSubmit">
                <i class="fa fa-save"></i>
                Save
              </button>
              <button class="save" @click="$router.push({name: 'Group',params: {pageno:pageno,perpage: perPage,order:currentSortRoute,search:search} })">
                <i class="fa fa-undo"></i>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import qs from 'qs';
import { AUTH_URL } from "actions/auth";
import { Validator } from 'vee-validate';

const dict = {
  custom: {
    new_user_title: {
      required: 'Your company owner is empty'
    },
    code: {
      required: () => 'Your company code is empty'
    },
    uic: {
      required: 'Your company uic is empty'
    },
    name: {
      required: 'Your company name is empty'
    }
  }
};

Validator.localize('en', dict);

const methods = {
  validateBeforeSubmit(e){
    this.$router.push({name: 'Group',params: {pageno:this.pageno,perpage: this.perPage,order:this.currentSortRoute,search:this.search} })
    /*this.$validator.validateAll().then((result) => {
      if (result) {
          let myForm = document.getElementById('frm'); 
          let formData = new FormData(myForm);
          const data = {}; 
          for (let [key, val] of formData.entries()) {
            Object.assign(data, {[key]: val})
          }

          if(this.$route.params.id!='' && this.$route.params.id!=undefined){
            var metodo = 'PUT';
            Object.assign(data, {id: this.$route.params.id})
          }else{
            var metodo = 'POST';
          }
          axios({url: AUTH_URL+'master/api/v2/groups', data: qs.stringify(data), method: metodo , 
            headers: { 
              'Authorization': 'Bearer '+localStorage.getItem("user-token"),
              'Accept' : '*\/*',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
              }
            })
            .then(resp => {
              //console.log(resp.data)
            })
            .catch(err => {
              this.error = true;
              console.error(err)
          })
          this.$router.push("/groups");
        return;
      }
    });*/
  }
};

export default {
  name: 'groupDetail',
  data() {
    return {
      titlePage: 'New Group',
      currentId: 0,
      detail: [],
      theme: '',
      new_user_title: '',
      icon: '',
      name: '',
      landing_page: '',
      is_default: 0,
      pageno: this.$route.params.pageno,
      perPage: this.$route.params.perpage,
      currentSortRoute: this.$route.params.order,
      search: this.$route.params.search,
    };
  },
  components:{
    Validator
  },
  methods,
  mounted: function mounted(){

  },
  beforeMount: function beforeMount(){
    if(this.$route.params.id!='' && this.$route.params.id!=undefined){
        this.titlePage = 'Edit Group';
        this.currentId = this.$route.params.id;
        this.new_user_title = this.$route.params.dati.new_user_title;
        this.theme = this.$route.params.dati.theme;
        this.icon = this.$route.params.dati.icon;
        this.name = this.$route.params.dati.name;
        this.landing_page = this.$route.params.dati.landing_page;
        this.is_default = this.$route.params.dati.is_default;
    }
  }
}
</script>